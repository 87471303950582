import React from "react";
import SignupContainer from "../components/Signup/SignupContainer";

type Props = {};

const Signup = (props: Props) => {
  return (
    <div className="page">
      <SignupContainer />
    </div>
  );
};

export default Signup;
