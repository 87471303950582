import React from "react";
import SigninContainer from "../components/Signin/SigninContainer";

type Props = {};

const Signin = (props: Props) => {
  return (
    <div className="page">
      <SigninContainer />
    </div>
  );
};

export default Signin;
