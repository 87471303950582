import React from "react";
import useFetch from "react-fetch-hook";

const thisKey = "1bit";
const calculateHash = (request) => {
  /*
    Base sample hash ouput
    2a82e48abcec5374b6ec31960371f7d3
  */
  const md5 = require("md5");
  let code = "==";
  code += "api/restaurants"; // $request->path();
  code += "?";
  code += ""; // http_build_query($request->all())
  code += "&";
  code += thisKey;

  return md5(md5(code));
};

const TestApi = () => {
  const url = "http://70bc-122-55-235-37.ngrok.io/api/restaurants";
  const options = {
    headers: { "X-Authorization": calculateHash(url) },
  };
  const { isLoading, data, error } = useFetch(url, options);

  // if (isLoading) return <h1>Loading</h1>;
  // if (error) {
  //   return <h1>Error</h1>;
  // }

  return (
    <div className="page">
      <h1 className="">TestAPI</h1>
    </div>
  );
};

export default TestApi;
